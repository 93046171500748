import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "window-and-pane-management-tricks-for-rstudio-and-your-os"
    }}><a parentName="h1" {...{
        "href": "#window-and-pane-management-tricks-for-rstudio-and-your-os"
      }}>{`Window and Pane Management Tricks for RStudio and your OS`}</a></h1>
    <p>{`Learning the hotkeys for the various programs I use has paid huge dividends in productivity over the years. I can knock out a task in a split second and move on while others are still moving their cursor over the right button.`}</p>
    <p>{`Learning the hotkeys for window management within my OS and pane management within RStudio have been particularly hepful, and in this post, I'd like to share those hotkeys. Learn them! Then you can feel the joy of flying through previously slow, mouse-based tasks and getting back to what we all love: analyzing data.`}</p>
    <h2 {...{
      "id": "rstudio-pane-shortcuts"
    }}><a parentName="h2" {...{
        "href": "#rstudio-pane-shortcuts"
      }}>{`RStudio Pane Shortcuts`}</a></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7792b8117bc728b697f3c01af02daac9/0b569/rstudio-panes.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACWElEQVQoz3WSa0/UQBSG+2PYbbvLpfepS++XvbTdXQSBRSXEqDEajGiQQMBrEIiJv/kx7a5ANH548s7MOXMyc84rnVz+5OTyFx8+XXP85Yajiyvenn1vODz9xpuTr7w6/szRpxveX1zz/uKKo/MfHJ5+5/XHr0281nfnP3h7domUTvaJy12cqMQMSqygxPBGGP4IKywQ8RiRbhBVe0TFDr1sAxGNMf0CfX3YUOf30ilO9ghpRQ8wbQ/b8VC7grZiIas2slJj0ZZN5I6grTrNvkbtOGi6x+raerOuURSLjhEjaVaMHxR4cYXrD7FFguXE2CJu1HETTJEgd12U+uJ/kP8UFE7OrHrKw80nRFGJF47w7xGEBa43QO4KFNVeFLD/on6huSgocraqPSbTGUlSESUFcVpTEqUFSVbSC4csqYKWYtFS7DnqHXU7WrKJokf1l0PiZEiSjkizgixf0C+aM8/LiPIx1d5Lytlziu1njGcvGGwd4JczvNE23nAbf7SNP9lHqqdq+wN0kbFqx6zayT84QUE23sVPN3DDigfRGBGUOH7R7HvxBC8Z86C/g5RtHpBPHxMVu3j9zbktkgkimTbqplPCYoc0n9Lpuiy1dFpt45bOsmBlrYdtBRjrI6R2R9Bq6Y09bqntsrBIHVvWPPLhFroZo9TD6YiFOqxo62hmgO3EmH6JpCy7c3+p1q3Pbqkb3jZYNQIGoy10I2o8Jyvm3KOK2bxON3xsO8QOKqSuEdHVArp6MNf76CHqmo/m9gkHj9Cc/C5PD+hoAZqTYrg5whti+BW/Adt0nzd68r7oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "default RStudio pane layout",
            "title": "default RStudio pane layout",
            "src": "/static/7792b8117bc728b697f3c01af02daac9/c1b63/rstudio-panes.png",
            "srcSet": ["/static/7792b8117bc728b697f3c01af02daac9/5a46d/rstudio-panes.png 300w", "/static/7792b8117bc728b697f3c01af02daac9/0a47e/rstudio-panes.png 600w", "/static/7792b8117bc728b697f3c01af02daac9/c1b63/rstudio-panes.png 1200w", "/static/7792b8117bc728b697f3c01af02daac9/d61c2/rstudio-panes.png 1800w", "/static/7792b8117bc728b697f3c01af02daac9/97a96/rstudio-panes.png 2400w", "/static/7792b8117bc728b697f3c01af02daac9/0b569/rstudio-panes.png 2878w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`RStudio's multi-column layout is great when you're just starting with RStudio and you need to see everything it can do. If you're like me, however, you'll find yourself annoyed at how much screen real-estate is occupied by parts of the UI that you rarely need. Knowing the hotkeys for resizing panes can help you maximize the screen realestate for the pane that's most relevant for your work.`}</p>
    <h3 {...{
      "id": "zoom-leftcenter-column"
    }}><a parentName="h3" {...{
        "href": "#zoom-leftcenter-column"
      }}>{`Zoom Left/Center Column`}</a></h3>
    <p><inlineCode parentName="p">{`Ctrl + Alt + Shift + F12`}</inlineCode>{` => `}<inlineCode parentName="p">{`Alt + Shift + 1`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "/789ea680bde35a385429c2e384a2fbbc/expand-left-panes.gif",
        "alt": "expand/shrink source editor pane"
      }}></img></p>
    <p>{`The default hotkey for this isn't so useful so I mapped it to `}<inlineCode parentName="p">{`Alt + Shift + 1`}</inlineCode>{` instead.`}</p>
    <h3 {...{
      "id": "zoom-right-column"
    }}><a parentName="h3" {...{
        "href": "#zoom-right-column"
      }}>{`Zoom Right Column`}</a></h3>
    <p><inlineCode parentName="p">{`Ctrl + Alt + Shift + F11`}</inlineCode>{` => `}<inlineCode parentName="p">{`Alt + Shift + 2`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "/0735e7d057c42c9834c1e1c38862938f/expand-right-panes.gif",
        "alt": "expand/shrink source editor pane"
      }}></img></p>
    <p>{`Same deal here. The default hotkey for this isn't so useful so I mapped it to `}<inlineCode parentName="p">{`Alt + Shift + 2`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "expandshrink-source-editor-pane"
    }}><a parentName="h3" {...{
        "href": "#expandshrink-source-editor-pane"
      }}>{`Expand/Shrink Source Editor Pane`}</a></h3>
    <p><inlineCode parentName="p">{`Ctrl + Shift + 1`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "/186c9a3a15b1ec74fe7fe67f9b44f7af/expand-editor-pane.gif",
        "alt": "expand/shrink source editor pane"
      }}></img></p>
    <h3 {...{
      "id": "expandshrink-console-pane"
    }}><a parentName="h3" {...{
        "href": "#expandshrink-console-pane"
      }}>{`Expand/Shrink Console Pane`}</a></h3>
    <p><inlineCode parentName="p">{`Ctrl + Shift + 2`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "/747e89c85b20c5c4208ff9ee4761a9a6/expand-console-pane.gif",
        "alt": "expand/shrink console pane"
      }}></img></p>
    <h2 {...{
      "id": "os-window-shortcuts"
    }}><a parentName="h2" {...{
        "href": "#os-window-shortcuts"
      }}>{`OS Window Shortcuts`}</a></h2>
    <h3 {...{
      "id": "windows"
    }}><a parentName="h3" {...{
        "href": "#windows"
      }}>{`Windows`}</a></h3>
    <p>{`If you use Windows, at a minimum, you should know the hotkeys for sending windows between monitors and for making them take up half of a single screen. Here they are:`}</p>
    <h4 {...{
      "id": "maximize-a-window-on-the-left-half-of-a-screen"
    }}><a parentName="h4" {...{
        "href": "#maximize-a-window-on-the-left-half-of-a-screen"
      }}>{`Maximize a window on the left half of a screen`}</a></h4>
    <p><inlineCode parentName="p">{`Windows Key + Left Arrow`}</inlineCode></p>
    <h4 {...{
      "id": "maximize-a-window-on-the-right-half-of-a-screen"
    }}><a parentName="h4" {...{
        "href": "#maximize-a-window-on-the-right-half-of-a-screen"
      }}>{`Maximize a window on the right half of a screen`}</a></h4>
    <p><inlineCode parentName="p">{`Windows Key + Right Arrow`}</inlineCode></p>
    <h4 {...{
      "id": "sending-window-to-left-display"
    }}><a parentName="h4" {...{
        "href": "#sending-window-to-left-display"
      }}>{`Sending window to left display`}</a></h4>
    <p><inlineCode parentName="p">{`Windows Key + Shift + Left Arrow`}</inlineCode></p>
    <h4 {...{
      "id": "sending-window-to-right-display"
    }}><a parentName="h4" {...{
        "href": "#sending-window-to-right-display"
      }}>{`Sending window to right display`}</a></h4>
    <p><inlineCode parentName="p">{`Windows Key + Shift + Right Arrow`}</inlineCode></p>
    <h3 {...{
      "id": "mac"
    }}><a parentName="h3" {...{
        "href": "#mac"
      }}>{`Mac`}</a></h3>
    <p>{`Hotkeys for the same actions are useful to know on mac, but you'll need to install an extra window management tool to use hotkeys to manage your windows. I use `}<a parentName="p" {...{
        "href": "https://github.com/jigish/slate",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Slate`}</a>{`. Its free, open source, and pretty good. Here's my config:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`config defaultToCurrentScreen true
config nudgePercentOf screenSize
config resizePercentOf screenSize

layout product

# Push Bindings
bind right:ctrl;cmd  push right bar-resize:screenSizeX/2
bind left:ctrl;cmd   push left  bar-resize:screenSizeX/2
bind up:ctrl;cmd     push up    bar-resize:screenSizeY/2
bind down:ctrl;cmd   push down  bar-resize:screenSizeY/2

# Throw Bindings
bind 1:ctrl;alt         throw 0 resize
bind 2:ctrl;alt         throw 1 resize`}</code></pre></div>
    <p>{`With this config, the hotkeys to key window actions are:`}</p>
    <h4 {...{
      "id": "maximize-a-window-on-the-left-half-of-a-screen-1"
    }}><a parentName="h4" {...{
        "href": "#maximize-a-window-on-the-left-half-of-a-screen-1"
      }}>{`Maximize a window on the left half of a screen`}</a></h4>
    <p><inlineCode parentName="p">{`Cmd + Ctrl + Left`}</inlineCode></p>
    <h4 {...{
      "id": "maximize-a-window-on-the-right-half-of-a-screen-1"
    }}><a parentName="h4" {...{
        "href": "#maximize-a-window-on-the-right-half-of-a-screen-1"
      }}>{`Maximize a window on the right half of a screen`}</a></h4>
    <p><inlineCode parentName="p">{`Cmd + Ctrl + Right`}</inlineCode></p>
    <h4 {...{
      "id": "sending-window-to-left-display-1"
    }}><a parentName="h4" {...{
        "href": "#sending-window-to-left-display-1"
      }}>{`Sending window to left display`}</a></h4>
    <p><inlineCode parentName="p">{`Ctrl + Alt + 1`}</inlineCode></p>
    <h4 {...{
      "id": "sending-window-to-right-display-1"
    }}><a parentName="h4" {...{
        "href": "#sending-window-to-right-display-1"
      }}>{`Sending window to right display`}</a></h4>
    <p><inlineCode parentName="p">{`Ctrl + Alt + 2`}</inlineCode></p>
    <h2 {...{
      "id": "conclusion"
    }}><a parentName="h2" {...{
        "href": "#conclusion"
      }}>{`Conclusion`}</a></h2>
    <p>{`These hotkeys will help you move faster while doing data analysis. You don't even have to memorize the hotkeys immediately. As long as you know these RStudio commands exist, you can invoke them via the fairly new command palette by pressing `}<inlineCode parentName="p">{`Cmd + Shift + P`}</inlineCode>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/0b569/command-palette.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACR0lEQVQoz3WSa0sbQRiF82dM9pKse51NspfsLRuTmBhjirViBWlpBRUVUUrthaJtKfQ3P2Un0dqWfng4zDLv2ZlzpnZ994Pru59cfvjG1afvXNx+5eTmM6/Objg8vuTgzTkvXp2y//qMw7cXHJ1ec3z1Ue6rOL+95+TmC2fv7zl9d08tnx6QjncRyRgnHuPGY0RvQjea4vgbmG7Jut3HsApaZo7llvjhJvFgh26xjZ9OcOMRnXyKKBbUWlaM44V4IkTVfdbqNqYVkffnZMU2SbaF7WaYdoJlp1IdUaC3OujNNutmKFVRHDQ7pWa6KVE8Ikw3CeIhnp/hdwriZEQ36GM5MYrmUVcc6orLWsNBbfporTaKJlB0gaL5NBR3aeiLPrub+2zP90nSMWFvSNQb0g0HOF5CQ/WWgyuqdWVWmVYm1VpS/chKqPntkp3xHpOt56T5hCQbkeRjepV5vCFP+DhUobjSTDW6NPQ2SrMjaWgC1cmrK/dI0pIkHZBmGyRJSRAUCJHiiYSW0UHVBZruo+lC5tw0Q4Jii7A/Ix4+IxossIMSL59Tc6MRblhi+gWGl2J4Gesix2oXT+hLnG6JEwzQjK40/gPNQ3cyauXiiOHiJflkj7Cc0ylm+On0H0Q6oZPPaOcz6lUJT2NQxe8MDSuS1zLMQGpz9RyarTZ6FX7VcMOR2VVaVwVK9Uy0v8p6aFkGqjgoqieH5Ub1ia6KaKjuUnVfFlDNyPWKet1anlCzE3QzRvsP+gPWSu0UfTXz+E1qhO71+QUVmJcmWeNA3gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "command palette",
            "title": "command palette",
            "src": "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/c1b63/command-palette.png",
            "srcSet": ["/static/b46f6532f8b8dd8cbdfedd33ce1730a4/5a46d/command-palette.png 300w", "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/0a47e/command-palette.png 600w", "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/c1b63/command-palette.png 1200w", "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/d61c2/command-palette.png 1800w", "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/97a96/command-palette.png 2400w", "/static/b46f6532f8b8dd8cbdfedd33ce1730a4/0b569/command-palette.png 2878w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I hope these tips help you work faster because at data chimp, we're all about speed. If you're looking for ways to analyze data faster, `}<a parentName="p" {...{
        "href": "/"
      }}>{`check us out`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      